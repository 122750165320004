import styled from 'styled-components'

export const InfoInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > div > span {
    ${({ theme }) => theme.wev_font.Regular_16};
    color: ${({ theme }) => theme.wev_color.text};
  }
`
export const ResetBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  .material-symbols-rounded {
    color: ${({ theme }) => theme.wev_color.hint};
    font-size: 19px;
  }
`
export const Input = styled.div<{ isErrorLine: boolean }>`
  width: 100%;
  position: relative;
  text-align: left;
  > input {
    display: flex;
    text-align: unset;
    height: 59px;
    width: 100%;
    ${({ theme }) => theme.wev_font.Regular_16};
    outline: none;
    border-radius: 10px;
    border: 1px solid ${({ isErrorLine, theme }) => (isErrorLine ? theme.wev_color.delete : theme.wev_color.outline)};
    padding: 20px;
    /* :read-only {
      border: none;
      background-color: ${({ theme }) => theme.wev_color.sub_btn_bg};
      :focus {
        border: none;
      }
    } */
    :disabled {
      color: ${({ theme }) => theme.wev_color.hint};
    }

    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fff inset;
      -webkit-text-fill-color: #000;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
    :focus {
      box-shadow: none;
      border: 1px solid ${({ isErrorLine, theme }) => (isErrorLine ? theme.wev_color.delete : theme.wev_color.primary)};
    }
    ::placeholder {
      color: ${({ theme }) => theme.wev_color.placeholder};
      ${({ theme }) => theme.wev_font.Regular_16};
    }
    ::-webkit-inner-spin-button {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }
  #password-input {
    -webkit-text-security: disc;
  }
`
export const Func = styled.div`
  position: absolute;
  top: -7px;
  right: 0px;
`
