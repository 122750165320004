export const INPUT_VALIDATION_ERROR_KR = {
  name: '정확한 성명을 입력해 주세요',
  phone: '정확한 전화번호를 입력해 주세요',
  email: '이메일 형식을 확인해 주세요',
}

export const INPUT_VALIDATION_ERROR_EN = {
  name: 'Please enter your full name correctly',
  phone: 'Please enter a valid phone number',
  email: 'Please check the email format',
}

export const INPUT_VALIDATION_ERROR_JP = {
  name: '正確な氏名を入力してください',
  phone: '正しい電話番号を入力してください',
  email: 'メール形式を確認してください',
}
