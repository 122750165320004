import React from 'react'
import { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Login from '@src/features/account/components/login/Login'
import HeadContainer from '@src/components/HeadContainer'

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ['layout', 'auth'])),
  },
})
const LoginPage: NextPage = () => {
  return (
    <>
      <HeadContainer title="로그인" />
      <Login />
    </>
  )
}

export default LoginPage
