import { css } from 'styled-components'

export const pageCTAButton = css`
  height: 64px;
  width: 100%;
  border-radius: 10px;
  ${({ theme }) => theme.font.sBold_18}
  @media ${({ theme }) => theme.device.mobile} {
    height: 59px;
    ${({ theme }) => theme.font.sBold_16}
  }
`
export const pageInputButton = css`
  height: 59px;
  width: 100%;
  border-radius: 10px;
  ${({ theme }) => theme.wev_font.Regular_16}
`
export const modalButton = css`
  height: 39px;
  width: fit-content;
  border-radius: 5px;
  padding: 10px 20px;
  white-space: nowrap;
  ${({ theme }) => theme.wev_font.Medium_16}
`
export const listButton = css`
  height: 30px;
  width: fit-content;
  border-radius: 20px;
  padding: 5px 10px;
  white-space: nowrap;
  ${({ theme }) => theme.wev_font.Regular_14}
`

/*  ---------- color --------------- */
// 기본 버튼 컬러
export const defaultButtonColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`
export const colorLineButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.sub_btn_hover};
  border: 1px solid ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.primary};
`
// 색상이 채워진 버튼 컬러
export const filledButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.white};
`
// gray 라인만 있는 버튼 컬러
export const lineButtonColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.text};
`
// gray 배경의 버튼 컬러
export const grayButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`

// disable 버튼 컬러 (선택 불가)
export const disabledButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.info_bg};
  color: ${({ theme }) => theme.wev_color.hint};
  border: none;
`
// notActive 버튼 컬러 (비활성)
export const notActiveButtonColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`

// delete 버튼 컬러
export const deletedButtonColor = css`
  background-color: ${({ theme }) => theme.wev_color.delete_hover};
  color: ${({ theme }) => theme.wev_color.delete};
`

export const commonButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.white};
  :disabled {
    ${disabledButtonColor}
  }
`
