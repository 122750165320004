import Config from 'configs/config.export'
import React, { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import * as S from './LoginSocial.styled'

const LoginSocial = () => {
  const random = Math.random()
  const { t } = useTranslation('auth')
  const apiKey = process.env.NEXT_PUBLIC_KAKAO_API_KEY
  const clientId = process.env.NEXT_PUBLIC_APPLICATION_CLIENT_ID
  const redirectUri = `${Config().baseUrl}social/kakao/login-callback`
  const naverRedirectUri = `${Config().baseUrl}oauth/naver/login/callback`
  const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${apiKey}&redirect_uri=${redirectUri}&response_type=code`
  const naverUrl = `https://nid.naver.com/oauth2.0/authorize?client_id=${clientId}&redirect_uri=${naverRedirectUri}&response_type=code&state=${String(
    random,
  )}`

  useEffect(() => {
    const handleAppleIdLoad = () => {
      window.AppleID?.auth.init({
        clientId: 'wev.login.apple',
        scope: 'email',
        redirectURI: `${
          Config().mode === 'production'
            ? 'https://api.wev-charger.com/v1/oauth/apple/login/callback'
            : 'https://dev.wev-charger.com:11050/v1/oauth/apple/login/callback'
        }`,
        state: 'wev_apple_login',
      })
    }

    if (window.AppleID) {
      handleAppleIdLoad()
    } else {
      const appleIdScript = document.createElement('script')
      appleIdScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
      appleIdScript.onload = handleAppleIdLoad
      document.body.appendChild(appleIdScript)
    }
  }, [])

  return (
    <>
      <S.SocialWrapper>
        <div id="social_title">
          <div />
          <p>{`${t('login.또는')}`}</p>
          <div />
        </div>
        <div id="social_buttons">
          <Image
            src="/images/SNSLogin/Apple_login_circle.png"
            id="appleid-signin"
            data-color="black"
            data-border="true"
            data-type="sign in"
            className="hover:cursor-pointer w-14 h-14 mt-2"
            width={62}
            height={62}
            alt="애플 로그인 버튼"
          />
          <Image
            src="/images/SNSLogin/KaKao_login_circle.png"
            className="hover:cursor-pointer w-14 h-14 mt-2"
            onClick={() => (window.location.href = kakaoUrl)}
            width={62}
            height={62}
            alt="카카오 로그인 버튼"
          />
          <Image
            src="/images/SNSLogin/Naver_login_circle.png"
            className="hover:cursor-pointer w-14 h-14 mt-2"
            onClick={() => (window.location.href = naverUrl)}
            width={62}
            height={62}
            alt="네이버 로그인 버튼"
          />
        </div>
      </S.SocialWrapper>
    </>
  )
}

export default LoginSocial
