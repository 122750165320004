import React, { ReactNode } from 'react'
import Image from 'next/image'
import Logo_g from 'public/images/wev/wev_logo_gradient.png'
import Link from 'next/link'
import { Arrow_Back_Ios } from '@src/assets/icons'
import * as S from './SingleLayout.styled'
interface IProps {
  children: ReactNode
  title: string
  desc?: string
  backPath?: string
  maxWidth: number
  backFunc?: any
}

const SingleLayout = (props: IProps) => {
  return (
    <S.Main>
      {props.backFunc && (
        <S.BackButton onClick={props.backFunc}>
          <Arrow_Back_Ios />
          <p>뒤로</p>
        </S.BackButton>
      )}
      <Link href="/">
        <Image src={Logo_g} width={146} height={37} alt="위브이 로고" />
      </Link>

      <S.Content maxWidth={props.maxWidth}>
        <div id="layout_text">
          <h1>{props.title}</h1>
          {props.desc ? <p dangerouslySetInnerHTML={{ __html: props.desc.replace(/\n/g, '<br/>') }}></p> : null}
        </div>
        {props.children}
      </S.Content>
    </S.Main>
  )
}

export default SingleLayout
