import React, { ComponentPropsWithoutRef, ReactNode, useRef } from 'react'
import { useRouter } from 'next/router'
import {
  INPUT_VALIDATION_ERROR_EN,
  INPUT_VALIDATION_ERROR_JP,
  INPUT_VALIDATION_ERROR_KR,
} from '@src/constant/validation'
import * as S from './BasicInput.styled'
interface IBasicInput extends ComponentPropsWithoutRef<'input'> {
  children?: ReactNode
  tag?: string
  error?: string
  isPrimary?: boolean
  isErrorLine?: boolean
}
const BasicInput = ({ tag, children, error, isPrimary = false, isErrorLine = false, ...props }: IBasicInput) => {
  const router = useRouter()

  const locale = router.locale || 'ko'

  const getLocaleData = (language: string) => {
    switch (language) {
      case 'en':
        return {
          data: INPUT_VALIDATION_ERROR_EN,
        }
      case 'jp':
        return {
          data: INPUT_VALIDATION_ERROR_JP,
        }
      default: // 한국어(kr)를 기본으로 설정
        return {
          data: INPUT_VALIDATION_ERROR_KR,
        }
    }
  }

  // @todo validation 번역 개선 필요
  const errorHandler = (error: string) => {
    if (router.asPath.includes('consult')) {
      if (error.includes('성명')) {
        return getLocaleData(locale).data.name
      } else if (error.includes('이메일')) {
        return getLocaleData(locale).data.email
      } else if (error.includes('전화번호')) {
        return getLocaleData(locale).data.phone
      } else {
        return ''
      }
    } else {
      return error
    }
  }
  return (
    <S.InfoInput>
      {tag && (
        <div className="flex w-full gap-[20px] items-center justify-between mb-[10px]">
          <span>
            {isPrimary ? <span className="text-[#583EEE]">* </span> : null}
            {tag}
          </span>
          <span>{error && <p className="text-[#e91212] text-[12px]">{errorHandler(error)}</p>}</span>
        </div>
      )}
      <S.Input isErrorLine={isErrorLine}>
        <input {...props} />
        {children && <S.Func>{children}</S.Func>}
      </S.Input>
    </S.InfoInput>
  )
}

export default BasicInput
