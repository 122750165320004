import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 100px 0px;
  max-width: 1280px;
  min-height: 90%;
  height: 100%;
  position: relative;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 60px 0px;
  }
`
export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  border-radius: 100px;
  padding: 10px 15px;
  color: ${({ theme }) => theme.wev_color.text};
  ${({ theme }) => theme.wev_font.sBold_16};
  position: absolute;
  top: 100px;
  left: 20px;
  > p {
    height: 20px;
  }
`
export const Content = styled.div<{ maxWidth: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 52px;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  width: ${({ maxWidth }) => `${maxWidth}px`};
  text-align: center;

  #layout_text {
    margin-bottom: 50px;
    > h1 {
      color: ${({ theme }) => theme.wev_color.text};
      ${({ theme }) => theme.wev_font.Bold_36};
    }
    > p {
      line-height: 1.2;
      color: ${({ theme }) => theme.wev_color.text};
      ${({ theme }) => theme.wev_font.Regular_20};
      margin-top: 10px;
      @media ${({ theme }) => theme.device.mobile} {
        ${({ theme }) => theme.wev_font.Regular_16}
      }
    }
    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 28px;
    }
  }

  @media ${({ theme }) => theme.device.pc} {
    padding: 0px 30px;
    width: 100%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 30px;
    width: 100%;
  }
`
