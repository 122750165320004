import styled from 'styled-components'

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.wev_color.outline};
  #social_title {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;

    > div {
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.wev_color.outline};
    }
    > p {
      ${({ theme }) => theme.wev_font.Regular_16};
      color: ${({ theme }) => theme.wev_color.hint};
      width: fit-content;
      white-space: nowrap;
    }
  }
  #social_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 30px;
    > img {
      width: 62px;
      height: 62px;
    }
  }
`
