import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { accountApis } from '@src/features/account/api'
import { BasicInput } from '../../../../components/commons/input/formInput/basicInput'
import Cookies from 'universal-cookie'
import ReCAPTCHA from 'react-google-recaptcha'
import Config from 'configs/config.export'
import LoginSocial from './loginSocial/LoginSocial'
import SingleLayout from '@src/layouts/singleLayout/SingleLayout'
import BasicButton from '../../../../components/commons/button/BasicButton/BasicButton'
import checkAccessToken from '@src/utils/auth/checkAccessToken'
import { Toast_Warning } from '@src/assets/icons'
import * as S from './Login.styled'

const Login = () => {
  const cookies = new Cookies()
  const router = useRouter()
  const { t } = useTranslation('auth')
  const recaptchaRef: any = useRef({})
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const hasAccessToken = checkAccessToken()
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const token = await recaptchaRef.current?.executeAsync()

    const data = {
      email: email,
      password: password,
      token,
    }

    const userLogin = async () => {
      try {
        await accountApis.authLogin(data).then((res) => {
          setIsLoading(false)
          if (res.data.result_code === 'OK') {
            cookies.set('Wev_accessToken', res.data.result_data.access_token, {
              path: '/',
            })
            cookies.set('Wev_refreshToken', res.data.result_data.refresh_token, {
              path: '/',
            })
            if (sessionStorage.getItem('wev.authorization.currentUrl')) {
              router
                .replace(`${sessionStorage.getItem('wev.authorization.currentUrl')}`)
                .then(() => sessionStorage.removeItem('wev.authorization.currentUrl'))
            } else {
              router.replace('/')
            }
          }
        })
      } catch (err: any) {
        setIsLoading(false)
        console.log(err)
        toast.dark(err.response?.data?.result_message || '오류가 발생했습니다. 잠시 후 다시 시도해주세요.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          closeButton: false,
          icon: <Toast_Warning />,
        })
        if (err.response.data.login_fail_count && err.response.data.login_fail_count !== 0) {
          if (err.response.data.login_fail_count >= 5) {
            router.push(
              {
                pathname: '/account/locked',
                query: { email: email },
              },
              '/account/locked',
              { shallow: true },
            )
          }
        } else if (err.response.data.result_code === 'MEMBER_MFA_REQUIRED') {
          router.push(
            {
              pathname: '/account/security',
              query: { email: email, requestId: err.response.data.result_data.requestId },
            },
            '/account/security',
            { shallow: true },
          )
          return
        } else if (err.response.data.result_code === 'IS_ACCOUNT_SECURITY_LOCKED') {
          router.push(
            {
              pathname: '/account/locked',
              query: { email: email },
            },
            '/account/locked',
            { shallow: true },
          )
        }
      }
    }
    userLogin()
    setIsLoading(true)
    setTimeout(() => recaptchaRef.current?.reset(), 600)
  }

  const inputEmail = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {
      currentTarget: { value },
    } = event
    setEmail(value)
  }

  const inputPassword = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {
      currentTarget: { value },
    } = event
    setPassword(value)
  }

  useEffect(() => {
    if (hasAccessToken) {
      router.replace('/')
    }
  }, [])

  return (
    <>
      <SingleLayout title={`${t('login.로그인')}`} maxWidth={420}>
        <S.LoginForm onSubmit={(e) => submitHandler(e)}>
          <BasicInput
            id="email"
            name="email"
            tag={`${t('login.이메일')}`}
            type="email"
            required
            value={email}
            onChange={inputEmail}
            autoComplete="email"
            placeholder={`${t('login.이메일을 입력해 주세요')}`}
          />
          <BasicInput
            id="password"
            name="password"
            tag={`${t('login.비밀번호')}`}
            type="password"
            required
            value={password}
            onChange={inputPassword}
            autoComplete="current-password"
            placeholder={`${t('login.비밀번호를 입력해 주세요')}`}
          />
          <div className="w-full mt-[30px]">
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={Config().recaptcha} />
            <BasicButton color="Filled" type="submit" isLoading={isLoading}>
              {t('login.로그인')}
            </BasicButton>
          </div>
          <S.FindAccount>
            <button onClick={() => router.push('/account/find_id')} type="button">
              {t('login.아이디 찾기')}
            </button>
            <div />
            <button onClick={() => router.push('/account/find_pw')} type="button">
              {t('login.비밀번호 찾기')}
            </button>
          </S.FindAccount>
        </S.LoginForm>
        <LoginSocial />
        <S.SignUpGuide href="/sign_up">
          {t('login.아직 회원이 아니신가요?')} <div>{t('login.회원가입')}</div>
        </S.SignUpGuide>
      </SingleLayout>
    </>
  )
}

export default Login
