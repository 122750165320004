import Link from 'next/link'
import styled from 'styled-components'

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`
export const FindAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  ${({ theme }) => theme.wev_font.Regular_16};
  > div {
    width: 1px;
    height: 20px;
    background-color: ${({ theme }) => theme.wev_color.outline};
  }
`
export const SignUpGuide = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 30px;
  cursor: pointer;
  ${({ theme }) => theme.wev_font.Regular_16};
  > div {
    color: ${({ theme }) => theme.wev_color.primary};
  }
`
